import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import { Seo } from 'components/Seo';
import { CookiesConsent } from 'components/CookiesConsent';
import { INotFoundPage } from 'src/interfaces/INotFoundPage';
import { getImageUrl } from 'utils/getImageUrl';
import { Button } from 'components/Button';
import classes from './NotFoundPageLayout.module.scss';
import 'src/styles/main.scss';

export const query = graphql`
    query NotFoundPage($locale: String!, $strapiId: Int!) {
        global: strapiGlobal(locale: { eq: $locale }) {
            siteName
            locale
            logo {
                url
                width
                height
                mime
            }
            footerContent {
                footerNavigation {
                    id
                    url
                    text
                    newTab
                    type
                    column
                }
                footerBottom {
                    id
                    url
                    text
                    newTab
                    type
                }
                socialTitle
                socialLinks {
                    id
                    type
                    url
                }
                licensesLinks {
                    id
                    url
                    text
                    icon {
                        url
                        width
                        height
                        mime
                    }
                }
                copyright
            }
            defaultSeo {
                metaTitle
                metaDescription
            }
            favicon {
                width
                ext
                hash
                height
                mime
                name
                size
                provider
                url
            }
            navigationLinks {
                id
                url
                newTab
                text
                type
            }
            cookiesConsent {
                consentMessage
                acceptButtonText
                declineButtonText
            }
            recaptchaV2Key
            latestNewsHeader
            page404 {
                buttonText
                description
                title
                image {
                    url
                    name
                }
                link
                newTab
            }
        }
        pages: allStrapiPage(
            filter: { displayInNavigation: { eq: true }, locale: { eq: $locale } }
            sort: { fields: [sort], order: ASC }
        ) {
            edges {
                node {
                    slug
                    title
                    locale
                }
            }
        }
        parents: allStrapiParent(filter: { locale: { eq: $locale } }) {
            edges {
                node {
                    id
                    sort
                    name
                    pages {
                        slug
                        title
                        locale
                    }
                }
            }
        }
        locale: strapiLocale(code: { eq: $locale }) {
            id
            name
            code
        }
        pageLocalization: strapiGlobal(strapiId: { eq: $strapiId }) {
            localizations {
                id
                locale
            }
            locale
        }
        pagesWithLocalization: allStrapiPage(filter: { locale: { ne: $locale } }) {
            nodes {
                strapiId
                slug
            }
        }
        locales: allStrapiLocale {
            edges {
                node {
                    name
                    code
                    isDefault
                }
            }
        }
        publicLocales: allStrapiGlobal {
            nodes {
                publishLocale
                locale
            }
        }
    }
`;

const NotFoundPage: FC<INotFoundPage> = ({ data }) => {
    const page = data.global.page404;

    return (
        <div className={classes.container}>
            <Seo global={data.global} />
            <Header
                parents={data.parents}
                global={data.global}
                pages={data.pages}
                locale={data.locale}
                locales={data.locales}
                localization={data.pageLocalization}
                pagesWithLocalization={data.pagesWithLocalization}
                publicLocales={data.publicLocales}
            />
            <main className={classes.content}>
                {page.title && <h1 className={classes.text}>{page.title}</h1>}
                <img className={classes.thankYou__image} src={getImageUrl(page?.image?.url || '')} alt="404" />

                <h3 className={classes.text}>{page.description}</h3>
                {!!page.buttonText && (
                    <Button className={classes.button} newTab={page.newTab} url={page.link}>
                        {page.buttonText}
                    </Button>
                )}
            </main>
            <Footer global={data.global} pages={data.pages} locale={data.locale} />
            <CookiesConsent data={data.global.cookiesConsent} />
        </div>
    );
};

export default NotFoundPage;
